import { config } from "@config/config";
import LogoVertical from "@public/images/logo-vertical.svg";
import CentropolLogo from "@public/images/partners/centropol.svg";
import EnergiePomahaLogo from "@public/images/partners/energie-pomaha.svg";
import UXFansLogo from "@public/images/partners/ux-fans.svg";
import { TextLink } from "@uxf/ui/text-link";
import dayjs from "dayjs";
import Link from "next/link";
import styles from "./footer.module.css";

const YEAR = dayjs().format("YYYY");

export function Footer() {
    return (
        <div className="relative mt-32 bg-surface-background-primary">
            <div className="container mx-auto max-w-[864px] px-6 py-12 lg:px-0">
                <div className="md:flex md:justify-between">
                    <div className="mb-16">
                        <LogoVertical />
                    </div>
                    <div className="grid gap-x-20 gap-y-8 md:grid-cols-[2fr_1fr] md:px-8">
                        <div className="space-y-8">
                            <div>
                                <p className="uxf-typo-h5 mb-4">Informace</p>
                                <div className="flex flex-col space-y-2">
                                    <TextLink
                                        href="/documents/podminky-uzivani-aplikace.pdf"
                                        target="_blank"
                                        className="no-underline"
                                    >
                                        Zásady pro užití aplikace
                                    </TextLink>
                                    <TextLink
                                        href="/documents/zpracovani-osobnich-udaju.pdf"
                                        target="_blank"
                                        className="no-underline"
                                    >
                                        Zásady ochrany osobních údajů
                                    </TextLink>
                                </div>
                            </div>
                            <div>
                                <p className="uxf-typo-h5 mb-4">Kontakt</p>
                                <TextLink
                                    className="no-underline"
                                    target="_blank"
                                    href={`mailto:${config.SUPPORT_EMAIL}`}
                                >
                                    {config.SUPPORT_EMAIL}
                                </TextLink>
                            </div>
                        </div>
                        <div>
                            <p className="uxf-typo-h5 mb-4">Spolupráce</p>
                            <div className="space-y-4">
                                <div>
                                    <Link href="https://www.centropol.cz/" target="_blank" rel="noopener noreferrer">
                                        <CentropolLogo className="w-[125px]" />
                                    </Link>
                                </div>
                                <div>
                                    <Link href="http://energiepomaha.cz/" target="_blank" rel="noopener noreferrer">
                                        <EnergiePomahaLogo className="w-[70px]" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 flex justify-between gap-4 border-t pt-6 max-sm:flex-col sm:items-center">
                    <div>© {YEAR} Energetická expedice s.r.o.</div>
                    <div>
                        <Link href="https://www.uxf.cz" target="_blank" className="flex items-center gap-2">
                            <span>Tuto stránku zapojili</span> <UXFansLogo />
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.wave} />
        </div>
    );
}
